import {put, select} from 'redux-saga/effects';
import {replace, resolveRoute, END_SIDE_EFFECTS_RUNNING, findMatchingRoutePath} from '@computerrock/formation-router';
import routePaths from '../routePaths';
import {bcFeatureRoutes, bcFeatureTabs} from './bcFeatures';

export const ensureRoute = function* ensureRoute() {
    const {location} = yield select(state => state.router);
    const bcFeatureRouteMatch = findMatchingRoutePath(bcFeatureRoutes, location.pathname);
    const bcFeatureActiveTabMatch = bcFeatureTabs.includes(location.query?.activeTab);

    if (bcFeatureRouteMatch || bcFeatureActiveTabMatch) {
        yield put(replace(resolveRoute(routePaths.ROUTE_ACCESS_DENIED)));
    }

    if (location.pathname === '/') {
        yield put(replace(resolveRoute(routePaths.DASHBOARD)));
        return END_SIDE_EFFECTS_RUNNING;
    }
};
